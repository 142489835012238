export default function componentStyleOverrides(theme) {
    // const mode = theme.customization?.mode;
    return {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 500,
                    borderRadius: '4px'
                }
            }
        },
        MuiPaper: {
            defaultProps: {
                elevation: 0
            },
            styleOverrides: {
                rounded: {
                    borderRadius: `${theme?.customization?.borderRadius}px`
                }
            }
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    padding: '24px'
                },
                title: {
                    fontSize: '1.125rem'
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: '24px'
                }
            }
        },
        MuiCardActions: {
            styleOverrides: {
                root: {
                    padding: '24px'
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: `${theme?.customization?.borderRadius}px`
                },
                input: {
                    fontWeight: 500,
                    borderRadius: `${theme?.customization?.borderRadius}px`
                },
                notchedOutline: {
                    borderRadius: `${theme?.customization?.borderRadius}px`
                }
            }
        },
        MuiSlider: {
            styleOverrides: {
                root: {
                    '&.Mui-disabled': {}
                },
                mark: {
                    width: '4px'
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    opacity: 1
                }
            }
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    padding: '16px'
                }
            }
        }
    };
}
