// 定义 message 对象
let _messageApp = null;

// 暴露一个方法用于在 App 中注入此 message 对象
export const injectMessageToWebsocket = (messageObj) => {
    _messageApp = messageObj;
};

const _CONNECTING = 0; // 正在连接
const OPEN = 1; // 连接成功，可以通信
const _CLOSING = 2; // 连接正在关闭
const _CLOSED = 3; // 连接已经关闭，或者连接失败

const websocketUrl = process.env.NODE_ENV === 'production' ? 'wss://websocket.ramses.cn' : 'ws://websocket.ramses.cn:81';
const recreateWaitTime = 1000 * 2; // 连接失败，2s 后重新创建连接
let ws = null; // 定义一个变量，保存 WebSocket 实例的引用
let lockRecreate = false; // 等待重新创建连接时，避免提前操作

// 发送给服务端的心跳包消息，按实际需求定义
const message = 'ping';
// 定义一个心跳包控制器，拥有两个方法 start、reset，
const heartbeatController = {
    timeout: 1000 * 3, // 3 秒钟一次心跳（根据服务端设置的超时时间而定）
    timeoutObj: null,
    severTimeoutObj: null,
    start() {
        if (ws && ws.readyState === OPEN) {
            this.timeoutObj = setTimeout(() => {
                ws.send(message);
                this.severTimeoutObj = setTimeout(() => {
                    ws.close();
                }, this.timeout);
            }, this.timeout);
        }
    },
    reset() {
        if (this.timeoutObj) {
            clearTimeout(this.timeoutObj);
            this.timeoutObj = null;
        }
        if (this.severTimeoutObj) {
            clearTimeout(this.severTimeoutObj);
            this.severTimeoutObj = null;
        }
        return this;
    }
};

// 惰性函数，只需首次判断浏览器是否有 WebSocket 构造器
function createWebsocket() {
    if ('WebSocket' in window && typeof window.WebSocket === 'function') {
        createWebsocket = function () {
            ws = new WebSocket(websocketUrl);
            initEventHandler();
        };
        createWebsocket();
    }
}

// 为创建的 WebSocket 实例绑定事件处理程序
function initEventHandler() {
    ws.onopen = () => {
        console.log('Connection open ...');
        heartbeatController.reset().start();
    };

    ws.onmessage = (evt) => {
        console.log('Received Message: ' + evt.data);
        heartbeatController.reset().start(); // 收到消息说明服务器正常连接，清除 severTimeoutObj
    };

    ws.onclose = () => {
        console.log('Connection closed.');
        heartbeatController.reset();
        recreateWebsocket(); // 重新创建连接
    };

    ws.onerror = (error) => {
        console.log('Connection error: ' + error);
        heartbeatController.reset();
        recreateWebsocket(); // 重新创建连接
    };
}

// 没创建连接成功，进行重新创建连接
function recreateWebsocket() {
    if (lockRecreate) {
        return;
    }
    lockRecreate = true;
    setTimeout(() => {
        createWebsocket();
        lockRecreate = false;
    }, recreateWaitTime);
}

// 手动创建 WebSocket 实例
createWebsocket();

// 监听窗口关闭事件，当窗口关闭时，主动去关闭 WebSocket 连接，防止连接还没断开就关闭窗口，server 端会抛异常。
window.onbeforeunload = function () {
    if (ws) {
        ws.close();
    }
};

export default ws;
