import LocalFireDepartmentOutlinedIcon from '@mui/icons-material/LocalFireDepartmentOutlined';
import AlbumOutlinedIcon from '@mui/icons-material/AlbumOutlined';

// constant
const icons = { LocalFireDepartmentOutlinedIcon, AlbumOutlinedIcon };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const asource = {
    id: 'asource-manage',
    title: 'Asource Manage',
    type: 'group',
    children: [
        {
            id: 'asource-actor',
            title: 'Actor',
            type: 'item',
            url: '/asource/actor',
            icon: icons.LocalFireDepartmentOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'asource-work',
            title: 'Work',
            type: 'item',
            url: '/asource/work',
            icon: icons.AlbumOutlinedIcon,
            breadcrumbs: false
        }
    ]
};

export default asource;
