import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import customizationReducer from './customizationReducer';
import userInfoReducer from './userInfoReducer';

export const store = configureStore({
    reducer: {
        customization: persistReducer(
            {
                key: 'customization',
                storage
            },
            customizationReducer
        ),
        userInfo: userInfoReducer
    },
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk]
});

export const persistor = persistStore(store);
