/**
 * Color intention that you want to used in your theme
 * @param {JsonObject} theme Theme customization object
 */

export default function themePalette(theme) {
    const mode = theme.customization?.mode ?? 'light';
    return {
        mode,
        primary: {
            light: theme.colors?.primaryLight,
            main: theme.colors?.primaryMain,
            dark: theme.colors?.primaryDark,
            200: theme.colors?.primary200,
            800: theme.colors?.primary800
        },
        secondary: {
            light: theme.colors?.secondaryLight,
            main: theme.colors?.secondaryMain,
            dark: theme.colors?.secondaryDark,
            200: theme.colors?.secondary200,
            800: theme.colors?.secondary800
        },
        error: {
            light: theme.colors?.errorLight,
            main: theme.colors?.errorMain,
            dark: theme.colors?.errorDark
        },
        warning: {
            light: theme.colors?.warningLight,
            main: theme.colors?.warningMain,
            dark: theme.colors?.warningDark
        },
        info: {
            light: theme.colors?.infoLight,
            main: theme.colors?.infoMain,
            dark: theme.colors?.infoDark
        },
        orange: {
            light: theme.colors?.orangeLight,
            main: theme.colors?.orangeMain,
            dark: theme.colors?.orangeDark
        },
        success: {
            light: theme.colors?.successLight,
            main: theme.colors?.successMain,
            dark: theme.colors?.successDark,
            200: theme.colors?.success200
        },
        grey: {
            50: theme.colors?.grey50,
            100: theme.colors?.grey100,
            500: theme.colors?.grey500,
            600: theme.colors?.grey600,
            700: theme.colors?.grey700,
            900: theme.colors?.grey900
        },
        background: {
            paper: mode === 'light' ? '#ffffff' : '#171a23',
            default: mode === 'light' ? '#eef2f6' : '#0e1215'
        }
    };
}
