import { useState, createContext, useContext } from 'react';
import { Alert, Snackbar } from '@mui/material';

const MessageContext = createContext();

function useMessage() {
    const context = useContext(MessageContext);
    if (!context) {
        throw new Error('useToast must be used within a ToastProvider');
    }
    return context;
}

const MessageProvider = ({ children }) => {
    const [open, setOpen] = useState(false);
    const [type, setType] = useState('info');
    const [content, setContent] = useState('hallo');

    const handleClose = (_e, reason) => {
        if (reason === 'timeout') {
            setOpen(false);
        }
    };

    const value = {
        commonAction: (content) => {
            setOpen(true);
            setContent(content);
        },
        success(content) {
            setType('success');
            this.commonAction(content);
        },
        error(content) {
            setType('error');
            this.commonAction(content);
        },
        info(content) {
            setType('info');
            this.commonAction(content);
        },
        warning(content) {
            setType('warning');
            this.commonAction(content);
        }
    };

    return (
        <MessageContext.Provider value={value}>
            {children}
            <Snackbar
                open={open}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={handleClose}
            >
                <Alert severity={type}>{content}</Alert>
            </Snackbar>
        </MessageContext.Provider>
    );
};

export { MessageProvider, useMessage };
