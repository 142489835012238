import { createTheme } from '@mui/material/styles';

// assets
import colors from 'assets/scss/_themes-vars.module.scss';

// project imports
import componentStyleOverrides from './compStyleOverride';
import themePalette from './palette';
import themeTypography from './typography';

/**
 * Represent theme style and structure as per Material-UI
 * @param {JsonObject} customization customization parameter object
 */

export const theme = (customization) => {
    const themeOptions = {
        direction: 'ltr',
        palette: themePalette({ colors, customization }),
        typography: themeTypography({
            colors,
            customization // 因为要使用自定义圆角和字体，所以传入
        })
    };

    const theme = createTheme(themeOptions);

    theme.components = componentStyleOverrides({
        colors,
        customization
    });

    return theme;
};

export default theme;
