import { createSlice } from '@reduxjs/toolkit';

// action - customization reducer
export const SET_MODE = '@customization/SET_MODE';
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';

const initialState = {
    mode: 'light', // 暗黑模式
    fontFamily: `'Roboto', sans-serif`, // 字体
    borderRadius: 12, // 圆角
    opened: true, // 左侧菜单展开状态
    isOpen: '/', // 菜单选中状态
};

const customizationSlice = createSlice({
    name: 'customization',
    initialState,
    reducers: {
        setCustomization(state, action) {
            switch (action.payload.type) {
                case SET_MODE:
                    state.mode = action.payload.value;
                    break;
                case MENU_OPEN:
                    state.isOpen = action.payload.value;
                    break;
                case SET_MENU:
                    state.opened = action.payload.value;
                    break;
                case SET_FONT_FAMILY:
                    state.fontFamily = action.payload.value;
                    break;
                case SET_BORDER_RADIUS:
                    state.borderRadius = action.payload.value;
                    break;
                default:
                    return state;
            }
        }
    }
});

export const { setCustomization } = customizationSlice.actions;

export default customizationSlice.reducer;
