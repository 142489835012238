import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';

import { MessageProvider, useMessage } from 'contexts/Message';

// 引入向 request.js 中注入 message 对象的方法
import { injectMessageToRequest } from 'utils/request';
import { injectMessageToWebsocket } from 'utils/websocket';

// 因为受 dom 渲染限制
// 定义一个函数组件，专门用来向 request.js 中注入 message 对象
const InjectMessageFuncComp = () => {
    const messageObj = useMessage();
    injectMessageToRequest(messageObj);
    injectMessageToWebsocket(messageObj)
};

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <MessageProvider>
                    <InjectMessageFuncComp />
                    <CssBaseline />
                    <NavigationScroll>
                        <Routes />
                    </NavigationScroll>
                </MessageProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
