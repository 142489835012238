import qs from 'qs';
import { setCurrentUser } from 'store/userInfoReducer';
import { store } from 'store';

// 定义 message 对象
let message = null;

// 暴露一个方法用于在 App 中注入此 message 对象
export const injectMessageToRequest = (messageObj) => {
    message = messageObj;
};

// 请求网关
export const baseURL = process.env.NODE_ENV === 'production' ? 'https://ramses.cn/api' : 'http://localhost:3000/api';

// 文件上传路径
export const uploadPath = '/upload/file';

// 请求实例构造器
const RequestConstructor = function () {
    // 请求成功，response.json 回调统一处理
    const responseJsonCallback = (data) => {
        // 后端返回数据 code: 0 成功 1 失败
        if (data.code === 0) {
            return data.data;
        }
        return Promise.reject(data.message);
    };

    // 请求回调统一处理
    const responeCallback = (response) => {
        if (response.status === 200) {
            return response
                .json()
                .then(responseJsonCallback)
                .catch((err) => {
                    return Promise.reject(err);
                });
        } else if (response.status === 403) {
            // token 过期或未携带 token
            // 从全局 store 中清空当前用户信息
            store.dispatch(setCurrentUser({}));
            // 从 localStorage 中清除 token 字段
            window.localStorage.removeItem('token');
            message.error('登录已过期，请重新登录！');
            setTimeout(() => {
                window.location.href = '/login';
            }, 3000);
            return Promise.reject('登录已过期，请重新登录！');
        }
        return Promise.reject(`请求失败！code: ${response.status}`);
    };

    // 基本请求操作
    const baseRequestAction = (method, url, data) => {
        let requestUrl = baseURL + url;

        const options = {};
        options.method = method;
        options.headers = {};

        const token = window.localStorage.getItem('token');
        if (token) {
            options.headers.Authorization = token;
        }

        // 如果是 GET 请求，需要拼接 URL 参数
        if (method === 'GET') {
            if (data && Object.keys(data).length) {
                requestUrl += '?' + qs.stringify(data);
            }
        }

        // 如果是 POST、PUT 请求，需要设置 Content-Type 请求头属性
        if (['POST', 'PUT'].includes(method)) {
            options.headers['Content-Type'] = 'application/json';
            options.body = JSON.stringify(data);
        }

        return fetch(requestUrl, options)
            .then(responeCallback)
            .catch((err) => {
                // 无法连接至网关时，会捕获第一个错误，为一个错误对象，err.message 为 Failed to fetch
                // 自定义 err 为字符串，做兜底使用
                let msg = 'err';
                if (err.message && err.message === 'Failed to fetch') {
                    msg = '网关连接失败，请检查网络（或网关未启用）！';
                } else {
                    msg = err;
                }
                message.error(msg);
                return Promise.reject(msg);
            });
    };

    // 在构建对象的身上挂挂载 get、post、put、delete 方法，通过 instance[method] 调用
    this.get = (url, data) => {
        return baseRequestAction('GET', url, data);
    };

    this.post = (url, data) => {
        return baseRequestAction('POST', url, data);
    };

    this.put = (url, data) => {
        return baseRequestAction('PUT', url, data);
    };

    this.delete = (url) => {
        return baseRequestAction('DELETE', url);
    };
};

// 创建一个请求实例
const requestInstance = new RequestConstructor();

export default requestInstance;
