import React, { createContext, useContext, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { setCustomization, MENU_OPEN } from 'store/customizationReducer';

const LocationListenerContext = createContext(null);

const useLocationListener = () => {
    const ref = useContext(LocationListenerContext);
    return ref.current;
};

const LocationListenerProvider = (props) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const locationState = useRef({
        from: null,
        to: null
    });

    useEffect(() => {
        locationState.current.from = locationState.current.to;
        locationState.current.to = location;
        dispatch(setCustomization({ type: MENU_OPEN, value: location.pathname }));
    }, [location, dispatch]);

    return <LocationListenerContext.Provider value={locationState}>{props.children}</LocationListenerContext.Provider>;
};

export { LocationListenerProvider, useLocationListener };
