import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

// constant
const icons = { AccountCircleOutlinedIcon };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const systemManage = {
    id: 'system-manage',
    title: 'System Manage',
    type: 'group',
    children: [
        {
            id: 'user',
            title: 'UserManage',
            type: 'item',
            url: '/system/user',
            icon: icons.AccountCircleOutlinedIcon,
            breadcrumbs: false
        }
    ]
};

export default systemManage;
