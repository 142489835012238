import { createSlice } from '@reduxjs/toolkit';

const userInfoSlice = createSlice({
    name: 'userInfoSlice',
    initialState: {
        currentUser: {}
    },
    reducers: {
        setCurrentUser(state, action) {
            state.currentUser = action.payload;
        }
    }
});

export const { setCurrentUser } = userInfoSlice.actions;

export default userInfoSlice.reducer;
