import PropTypes from 'prop-types';

// material-ui
import { styled } from '@mui/material/styles';
import { Avatar, Card, CardContent, Grid, LinearProgress, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';

// assets
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';

// styles
const BorderLinearProgress = styled(LinearProgress)(() => ({
    borderRadius: 4,
    height: 10
}));

const CardStyle = styled(Card)(() => ({
    marginBottom: '22px',
    overflow: 'hidden',
    position: 'relative'
}));

// ==============================|| PROGRESS BAR WITH LABEL ||============================== //

function LinearProgressWithLabel({ value, ...others }) {
    return (
        <Grid container direction="column" spacing={1}>
            <Grid item>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <Typography variant="h6">Progress</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h6" color="inherit">{`${Math.round(50)}%`}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <BorderLinearProgress variant="determinate" value={value} {...others} />
            </Grid>
        </Grid>
    );
}

LinearProgressWithLabel.propTypes = {
    value: PropTypes.number
};

// ==============================|| SIDEBAR MENU Card ||============================== //

const MenuCard = () => {
    return (
        <CardStyle>
            <CardContent sx={{ p: '6px' }}>
                <List sx={{ p: 0, m: 0 }}>
                    <ListItem alignItems="flex-start" disableGutters sx={{ p: 0 }}>
                        <ListItemAvatar>
                            <Avatar variant="rounded">
                                <TableChartOutlinedIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={<Typography variant="subtitle1">Get Extra Space</Typography>}
                            secondary={<Typography variant="caption"> 25/50 GB</Typography>}
                        />
                    </ListItem>
                </List>
                <LinearProgressWithLabel value={50} />
            </CardContent>
        </CardStyle>
    );
};

export default MenuCard;
