import dashboard from './dashboard';
import utilities from './utilities';
import _asource from './asource';
import systemManage from './systemManage';

// ==============================|| MENU ITEMS ||============================== //

const menus = {
    // items: [dashboard, utilities, asource, systemManage],
    items: [dashboard, utilities, systemManage]
};

const flattenedResult = [];

// 树形数据铺平方法
const flat = (nodes) => {
    if (!nodes || nodes.length === 0) return [];
    nodes.forEach((node) => {
        flattenedResult.push({ ...node });
        return flat(node.children);
    });
};

flat(menus.items);

// 铺平的菜单数据
export const flattenedMenuItems = flattenedResult;

export default menus;
