import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@mui/material';

// project imports
import Logo from 'components/Logo';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
    return (
        <ButtonBase sx={{ width: '100%' }} disableRipple component={Link} to={'/'}>
            <Logo />
        </ButtonBase>
    );
};

export default LogoSection;
